function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
// SASS
import '../sass/main.scss';
import '../sass/mil-chat-box.scss';
require.resolve('underscore/underscore-min.js');
require.resolve('dropzone/dist/min/dropzone.min.js');
require('./libs/misc/iedetector');
require('./libs/misc/mil-reactions');
require('./libs/misc/mil-av-live');
//require('./partials/player')
//require('./partials/live')
require('./partials/select2-below');
require('./partials/engine');
$('.lang-switch').click(function (e) {
  e.preventDefault();
  var urlParams = new URLSearchParams(window.location.search);
  var lng = $(this).data('locale');
  console.debug($(this).data('locale'), lng === 'en', _typeof(lng));
  urlParams.set('lng', lng);
  window.location.search = urlParams;
});